// Material
import { makeStyles } from 'tss-react/mui';

// Styles
const useStyles = makeStyles()(() => ({
	slideContainer: {
		height: '100%',
	},
	slideWrapper: {
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
	},
	componentWrap: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
	},
	cursorPointer: {
		cursor: 'pointer',
	},
}));

export default useStyles;
