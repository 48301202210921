import Link from 'next/link';
import PropTypes from 'prop-types';

// material
import { Box } from '@mui/material';

// actions
import { isExternalUrl } from '@/store/actions';

export function LinkWraper({ link, children, className }) {
	const WrappedElement = <Box className={className}>{children}</Box>;

	if (!link) return WrappedElement;

	if (isExternalUrl(link)) {
		return (
			<a href={link} target="_blank" rel="noreferrer">
				{WrappedElement}
			</a>
		);
	}

	return (
		<Link href={link}>
			<a>{WrappedElement}</a>
		</Link>
	);
}

LinkWraper.propTypes = {
	link: PropTypes.string,
};
