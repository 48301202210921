// components
import Image from 'next/image';
import OTPInput from '../otpField';

// util
import { errorMessages } from '@/components/shared/liveCounsellingSection/constants';
import { textValues } from './constants';
import { validate } from './util';

// material
import {
	Box,
	Button,
	LinearProgress,
	TextField,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

//styles
import useStyles from './style';

//Image
import editIcon from '@/assets/icons/edit.png';
import { useSelector } from 'react-redux';

export default function FormSection({
	otpSent,
	curTime,
	setCurTime,
	isResendOtp,
	mobileNumberLabel,
	setStep,
	initialPayload,
	error,
	setError,
	lastName,
	firstName,
	city,
	email,
	edit,
	handleEdit,
	last10Digits,
	trackEvent,
	...props
}) {
	const { classes, cx } = useStyles();

	const textFieldRef = React.useRef(null);
	const [errorTexts, setErrorTexts] = React.useState({ username: '', otp: '' });
	const [values, setValues] = React.useState({
		username: props.phoneNumber || last10Digits || '',
		otp: '',
	});
	const isMobile = useSelector((state) => state.common.isMobile);
	const location = useSelector((state) => state.router.location);

	const handleChangeUserName = (e) => {
		const { value } = e.target;
		if (value.length < 11) {
			setValues((exisitngValue) => ({
				...exisitngValue,
				username: value,
			}));
		}
	};

	const handleChangeOTP = React.useCallback(
		(otp) => setValues((exisitngValue) => ({ ...exisitngValue, otp })),
		[]
	);

	const validateForm = (validateOTP) => {
		const validations = validate(
			values.username,
			values.otp,
			validateOTP,
			error,
			setError,
			errorMessages
		);

		setErrorTexts(validations);
		const fields = ['firstName', 'lastName', 'email', 'city'];
		let errorObj = { error: {} };
		fields.map((field) => {
			if (
				initialPayload[field] === undefined ||
				initialPayload[field] === null ||
				initialPayload[field] === ''
			) {
				errorObj.error[field] = errorMessages[field];
				setError({ ...error, ...errorObj.error });
				return false;
			} else {
				errorObj.error[field] = '';
			}
			return true;
		});

		if (
			validations.username ||
			validations.otp ||
			validations.firstName ||
			validations.lastName ||
			validations.email ||
			validations.city
		)
			return false;

		return true;
	};

	const handleResendOTP = () => {
		props.setResendOTP(!isResendOtp);

		if (validateForm(false)) {
			props.sendOTP(values.username, lastName, firstName, city, email, true);
			trackEvent('BUTTON_CLICKED', {
				eventAction: 'CLICKED',
				eventCategory: 'USER_EVENT',
				subSection: 'BASIC_DETAILS',
				eventLabel: textValues.resendOTPText,
			});
		}
	};
	const handleSendOTP = () => {
		if (validateForm(false)) {
			props.sendOTP(values.username, lastName, firstName, city, email);
			trackEvent('BUTTON_CLICKED', {
				eventAction: 'CLICKED',
				eventCategory: 'USER_EVENT',
				subSection: 'BASIC_DETAILS',
				eventLabel: textValues.buttonTextBeforeOTP,
			});
		}
	};
	const handleSubmit = () => {
		if (validateForm(true)) {
			props.onSubmit(values);
			trackEvent('BUTTON_CLICKED', {
				eventAction: 'CLICKED',
				eventCategory: 'USER_EVENT',
				subSection: 'BASIC_DETAILS',
				eventLabel: textValues.buttonTextAfterOTP,
			});
		}
	};

	React.useEffect(() => {
		const instanceOFTime = setInterval(() => {
			if (otpSent && curTime !== 0) setCurTime(curTime - 1);
		}, 1000);
		return () => clearInterval(instanceOFTime);
	}, [curTime, otpSent, setCurTime]);

	const handleWheel = (e) => e.preventDefault();

	React.useEffect(() => {
		const element = textFieldRef.current;
		if (!element) return;

		element.addEventListener('wheel', handleWheel, {
			passive: false,
		});

		return () => {
			element.removeEventListener('wheel', handleWheel, {
				passive: false,
			});
		};
	}, [textFieldRef.current]);

	const FormFields = React.useMemo(() => {
		const textFieldProps = {
			id: 'username',
			name: 'username',
			type: 'number',
			variant: 'outlined',
			margin: 'dense',
			required: true,
			fullWidth: true,
			autoFocus: true,
			classes: {
				root: cx(classes.inputFieldRoot, classes.hideNumberFieldArrows),
			},
			InputProps: { className: classes.inputFieldWrap },
			inputProps: { className: classes.inputField, min: 0 },
			helperText: errorTexts.username || null,
			error: !!errorTexts.username,
			value: values.username,
			disabled: edit,
			onChange: handleChangeUserName,
			onKeyDown: (e) => e.key === 'Enter' && e.preventDefault(),
			ref: textFieldRef,
		};

		const otpFieldProps = {
			className: classes.otpFieldWrap,
			onChangeOTP: handleChangeOTP,
			value: values.otp,
			autoFocus: true,
			length: 6,
			isNumberInput: true,
			inputClassName: classes.hideNumberFieldArrows,
			error: !!errorTexts.otp,
			helperText: errorTexts.otp || null,
		};
		return (
			<>
				<Box className={classes.numberFieldWrap}>
					<Box className={classes.numberInputFieldWrap}>
						<TextField {...textFieldProps} />
					</Box>
					{edit ? (
						<Box style={{ marginLeft: '5px' }}>
							<Button
								style={{ marginTop: '10px', color: '#78A5FF' }}
								onClick={handleEdit}
							>
								<Image
									src={editIcon}
									alt="Edit Icon"
									width={isMobile ? '36px' : '24px'}
									height={isMobile ? '36px' : '24px'}
								/>
								<span style={{ marginLeft: '10px' }}>edit</span>
							</Button>
						</Box>
					) : null}
				</Box>
				{otpSent && <OTPInput {...otpFieldProps} />}
			</>
		);
	}, [
		errorTexts.username,
		handleChangeOTP,
		handleChangeUserName,
		otpSent,
		values.username,
		edit,
	]);

	const OTPSendText = React.useMemo(() => {
		if (!otpSent) return null;

		if (curTime !== 0) {
			return <Typography>{`Resend OTP in ${curTime} seconds`}</Typography>;
		}

		return (
			<Typography>
				<Typography component="span" className={classes.addSpaceAfter}>
					{textValues.otpNotReceivedText}
				</Typography>
				<Typography
					component="span"
					color="primary"
					className={classes.cursorPointer}
					onClick={handleResendOTP}
				>
					{textValues.resendOTPText}
				</Typography>
			</Typography>
		);
	}, [otpSent, curTime, handleResendOTP]);

	return (
		<Box className={classes.formSectionContainer}>
			<form
				className={classes.formWrap}
				autoComplete={location.pathname.startsWith('/kiosk/') ? 'off' : 'on'}
			>
				<Box mb={1}>
					<Typography className={classes.questionLabel}>
						{mobileNumberLabel}
					</Typography>
				</Box>
				<Box mb={2}>{FormFields}</Box>
				<Box textAlign="center" width="100%" mb={1}>
					{props.loading ? (
						<LinearProgress color="primary" />
					) : (
						<Box height="4px"></Box>
					)}
				</Box>
				<Box className={classes.submitButtonWrap}>
					<Button
						fullWidth
						variant="contained"
						color="success"
						onClick={otpSent ? handleSubmit : handleSendOTP}
						disabled={props.loading}
					>
						{otpSent
							? textValues.buttonTextAfterOTP
							: textValues.buttonTextBeforeOTP}
					</Button>
					<Button
						fullWidth
						className={classes.backButton}
						onClick={() => setStep(2)}
						disabled={props.loading}
					>
						Back
					</Button>
				</Box>
			</form>
			<Box className={classes.otpTextWrap}>{OTPSendText}</Box>
		</Box>
	);
}
FormSection.propTypes = {
	setCurTime: PropTypes.func,
	curTime: PropTypes.number,
	otpSent: PropTypes.bool,
	sendOTP: PropTypes.func,
	setResendOTP: PropTypes.func,
	isResendOtp: PropTypes.bool,
	phoneNumber: PropTypes.string,
	onSubmit: PropTypes.func,
	analyticsProps: PropTypes.object,
	edit: PropTypes.bool,
	mobileNumberLabel: PropTypes.string,
	setStep: PropTypes.func,
	initialPayloa: PropTypes.object,
	error: PropTypes.object,
	setError: PropTypes.func,
	lastName: PropTypes.string,
	firstName: PropTypes.string,
	city: PropTypes.string,
	email: PropTypes.string,
	handleEdit: PropTypes.func,
	trackEvent: PropTypes.func.isRequired,
	loading: PropTypes.bool,
};
FormSection.defaultProps = {
	otpSent: false,
	sendOTP: false,
	isResendOtp: false,
	loading: false,
	analyticsProps: {},
};
