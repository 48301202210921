import { makeStyles } from 'tss-react/mui';

const commonImageWrapStyles = {
	position: 'relative',
	overflow: 'hidden',
	margin: '0 auto',
};

const useStyles = makeStyles()(
	(theme, { imageWidth, imageHeight, labelStyled }) => ({
		logoImageWrap: {
			borderRadius: '50%',
			width: imageWidth || '100%',
			height: imageWidth || '100%',
			backgroundColor: '#FF8042',
			...commonImageWrapStyles,
		},

		circleImageWrap: {
			borderRadius: '50%',
			width: imageWidth || '100%',
			height: imageWidth || '100%',
			...commonImageWrapStyles,
		},

		rectangleImageWrap: {
			width: imageWidth || '100%',
			paddingTop: imageHeight || '100%',
			borderRadius: '10px',
			...commonImageWrapStyles,
		},
		logo: {
			padding: '28px!important',
		},
		labelWrap: {
			marginTop: labelStyled ? '36.58px' : '10px',
			wordWrap: 'break-word',
		},
		label: {
			...(labelStyled ? { fontWeight: '500', fontSize: '18px' } : {}),
			textAlign: 'center',
		},
	})
);

export default useStyles;
