import PropTypes from 'prop-types';
//component
import MeetingModal from '@/components/shared/liveCounsellingSection/partials/MeetingModal';
import GenericButton from '@/components/widgets/genericButton';
import ImageComponent from '@/components/widgets/image';
import { goToExternalRoute } from '@/store/actions';

//material
import {
	Alert,
	Box,
	Button,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import { AdapterMoment as MomentUtils } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//moment
import moment from 'moment';

//hooks
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

//utils
import { redirectionLink } from '../../constants';

//api
import { createMeeting } from '@/api';
///styles
import { useStyles } from './style';

export default function StepFour({
	iconImage,
	formTitle,
	formDescription,
	selectDateLabel,
	selectTimeLabel,
	onModalClose,
	trackEvent,
}) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	let now = new Date();
	const currentDate = moment();
	const [selectedDate, setSelectedDate] = useState(currentDate);
	const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
	const [timeSlots, setTimeSlots] = useState([]);
	const [meetModal, setMeetModal] = useState(false);
	const [meetPayload, setMeetPayload] = useState({});
	const [meetError, setMeetError] = useState(false);
	const [modalDateTime, setModalDateTime] = useState('');
	const currentHour = now.getHours();
	let currentMinute = now.getMinutes();
	const meetingType = 'VIRTUAL';
	function generateTimeSlots() {
		const slots = [];
		let currentHours = currentHour;

		const isDateMatchSelectedDate = () => {
			if (selectedDate) return selectedDate.isSame(currentDate, 'day');
		};

		if (selectedDate && selectedDate.day() === 0) {
			return [];
		}

		if (isDateMatchSelectedDate()) {
			if (currentHour < 10) {
				currentHours = 10;
				currentMinute = 0;
			}
		} else {
			currentHours = 10;
			currentMinute = 0;
		}

		for (let hour = currentHours; hour < 19; hour++) {
			const startMinute =
				hour === currentHours ? Math.ceil(currentMinute / 30) * 30 : 0;
			for (let minute = startMinute; minute < 60; minute += 30) {
				if (hour === 19 && minute === 30) {
					break;
				}
				const formattedHour = hour % 12 || 12;
				const startMinuteStr = String(minute).padStart(2, '0');
				const endHour = String(
					hour + ((minute + 30 >= 60 ? 1 : 0) % 24)
				).padStart(2, '0');
				const formattedEndHour = endHour % 12 || 12;
				const endMinute = String((minute + 30) % 60).padStart(2, '0');
				const timeSlot = `${formattedHour}:${startMinuteStr}-${formattedEndHour}:${endMinute}`;
				slots.push(timeSlot);
			}
		}

		return slots;
	}
	function padZero(num) {
		return num.toString().padStart(2, '0');
	}
	function timezoneOffsetMinutesToISO8601(offsetMinutes) {
		const hours = Math.floor(Math.abs(offsetMinutes) / 60);
		const minutes = Math.abs(offsetMinutes) % 60;
		const sign = offsetMinutes < 0 ? '-' : '+';

		return `${sign}${padZero(hours)}:${padZero(minutes)}`;
	}
	function convertToPayload(selectedDate, selectedTimeSlot, meetingType) {
		const timezoneOffsetMinutes = 5 * 60 + 30;
		const formattedDate = selectedDate.format('YYYY-MM-DD');
		const [startTime, endTime] = selectedTimeSlot.split('-');
		let fromDatetime = null;
		let toDatetime = null;
		if (
			startTime === '10:00' ||
			startTime === '10:30' ||
			startTime === '11:00' ||
			startTime === '11:30'
		) {
			fromDatetime = `${formattedDate}T${startTime}:00${timezoneOffsetMinutesToISO8601(
				timezoneOffsetMinutes
			)}`;
			toDatetime = `${formattedDate}T${endTime}:00${timezoneOffsetMinutesToISO8601(
				timezoneOffsetMinutes
			)}`;
		} else if (startTime == '12:00' || startTime == '12:30') {
			if (startTime == '12:00') {
				fromDatetime = `${formattedDate}T${startTime}:00${timezoneOffsetMinutesToISO8601(
					timezoneOffsetMinutes
				)}`;
				toDatetime = `${formattedDate}T${endTime}:00${timezoneOffsetMinutesToISO8601(
					timezoneOffsetMinutes
				)}`;
			} else {
				fromDatetime = `${formattedDate}T${'12:30'}:00${timezoneOffsetMinutesToISO8601(
					timezoneOffsetMinutes
				)}`;
				toDatetime = `${formattedDate}T${'13:00'}:00${timezoneOffsetMinutesToISO8601(
					timezoneOffsetMinutes
				)}`;
			}
		} else {
			let startTimeStr = startTime && startTime.split('-')[0];
			let endTimeStr = endTime && endTime.split('-')[0];
			const startTime1 =
				startTimeStr &&
				parseInt(startTimeStr.split(':')[0]) +
					12 +
					':' +
					startTimeStr.split(':')[1];
			const endTime1 =
				endTimeStr &&
				parseInt(endTimeStr.split(':')[0]) +
					12 +
					':' +
					endTimeStr.split(':')[1];

			fromDatetime = `${formattedDate}T${startTime1}:00${timezoneOffsetMinutesToISO8601(
				timezoneOffsetMinutes
			)}`;
			toDatetime = `${formattedDate}T${endTime1}:00${timezoneOffsetMinutesToISO8601(
				timezoneOffsetMinutes
			)}`;
		}

		const payload = {
			from_datetime: fromDatetime,
			meeting_type: meetingType,
			to_datetime: toDatetime,
		};
		return payload;
	}
	function slotToTime(selectedTimeSlot) {
		const timeRange = selectedTimeSlot;
		const startTimeStr = timeRange.split('-')[0];

		const startTimeParts = startTimeStr.split(':');
		let startTimeHour = parseInt(startTimeParts[0]);
		const startTimeMinute = parseInt(startTimeParts[1]);
		const startTimeDate = new Date();
		let startTimeAMPM = null;
		startTimeDate.setHours(startTimeHour);
		startTimeDate.setMinutes(startTimeMinute);

		if (startTimeHour === 10 || startTimeHour === 11) {
			if (startTimeMinute) {
				startTimeAMPM = startTimeHour + ':' + startTimeMinute + ' AM';
			} else {
				startTimeAMPM = startTimeHour + ':' + '00' + ' AM';
			}
		} else {
			if (startTimeMinute) {
				startTimeAMPM = startTimeHour + ':' + startTimeMinute + ' PM';
			} else {
				startTimeAMPM = startTimeHour + ':' + '00' + ' PM';
			}
		}

		return startTimeAMPM;
	}
	function getDayWithSuffix(day) {
		if (day >= 11 && day <= 13) {
			return day + 'th';
		}
		switch (day % 10) {
			case 1:
				return day + 'st';
			case 2:
				return day + 'nd';
			case 3:
				return day + 'rd';
			default:
				return day + 'th';
		}
	}
	function dateToModalDate(selectedDate) {
		const originalDateStr = selectedDate;
		const originalDate = new Date(originalDateStr);

		const day = originalDate.getUTCDate();
		const month = originalDate.toLocaleString('default', { month: 'long' });

		const dayWithSuffix = getDayWithSuffix(day);

		const formattedDateString = dayWithSuffix + ' ' + month;
		return formattedDateString;
	}
	const handleTimeSlotChange = (event) => {
		setSelectedTimeSlot(event.target.value);

		setMeetPayload(
			convertToPayload(selectedDate, selectedTimeSlot, meetingType)
		);
	};
	const meetSheduled = () => {
		trackEvent('BUTTON_CLICKED', {
			event_action: 'CLICKED',
			eventCategory: 'USER_EVENT',
			eventLabel: 'Schedule call',
			subSection: 'SCHEDULE_MEETING',
		});
		setMeetPayload(
			convertToPayload(selectedDate, selectedTimeSlot, meetingType)
		);
		createMeeting(meetPayload)
			.then(() => {
				setModalDateTime(
					dateToModalDate(selectedDate) + ' ' + slotToTime(selectedTimeSlot)
				);
				setMeetModal(true);
			})
			.catch((error) => {
				if (error.response.data.error.type == 'DUPLICATE') {
					setMeetError({
						message: 'Meeting is already scheduled!',
						type: 'error',
						isOpen: true,
					});
				}
			});
	};
	const handleClose = () => {
		setMeetModal(false);
		onModalClose();

		trackEvent('MEETING_MODAL_CLOSED', {
			event_action: 'CLICKED',
			eventCategory: 'USER_EVENT',
			eventLabel: 'MEETING_MODAL_CLOSED',
			subSection: 'SCHEDULE_MEETING_MODAL',
		});
	};
	const handleDateChange = (date) => {
		setSelectedDate(date);
		setTimeSlots(generateTimeSlots());
		setMeetPayload(
			convertToPayload(selectedDate, selectedTimeSlot, meetingType)
		);
	};
	const handleErrorClose = () => {
		setMeetError({
			message: '',
			type: '',
			isOpen: false,
		});
	};

	useEffect(() => {
		setMeetPayload(
			convertToPayload(selectedDate, selectedTimeSlot, meetingType)
		);
	}, [selectedDate, selectedTimeSlot]);

	useEffect(() => {
		setTimeSlots(generateTimeSlots());
	}, []);

	useEffect(() => {
		setTimeSlots(generateTimeSlots());
		const slots = generateTimeSlots();

		if (slots && slots.length == 0) {
			const nextDay = moment(currentDate).add(1, 'day');
			setSelectedDate(nextDay);
		}
	}, [selectedDate]);

	return (
		<>
			<Box className={classes.formProfileCard}>
				{iconImage && iconImage.url && (
					<ImageComponent
						className={classes.profilePic}
						src={iconImage.url}
						alt={iconImage.alternativeText}
						width={isMobile ? '76px' : iconImage.width}
						height={isMobile ? '94px' : iconImage.height}
					/>
				)}
				<Box
					style={{
						marginLeft: 'auto',
						marginRight: 'auto',
						width: isMobile ? '65%' : '380px',
					}}
				>
					<Typography className={classes.formTitle}>
						<span>{formTitle}</span>
					</Typography>
				</Box>

				<Typography className={classes.formDescription}>
					{formDescription}
				</Typography>
			</Box>
			<Box className={classes.questionWrap}>
				<Typography className={classes.questionLabel}>
					{selectDateLabel}
				</Typography>
				<LocalizationProvider dateAdapter={MomentUtils}>
					<DatePicker
						value={selectedDate}
						disablePast
						onChange={handleDateChange}
						inputFormat="DD/MM/yyyy"
						renderInput={(params) => (
							<TextField
								fullWidth
								{...params}
								sx={{
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											borderRadius: '18px',
										},
									},
								}}
							/>
						)}
					/>
				</LocalizationProvider>
			</Box>
			<Box className={classes.questionWrap}>
				<Typography className={classes.questionLabel}>
					{selectTimeLabel}
				</Typography>
				<Select
					labelId="time-slot-label"
					id="time-slot-select"
					value={selectedTimeSlot}
					onChange={handleTimeSlotChange}
					style={{ width: '100%', borderRadius: '18px' }}
				>
					{timeSlots &&
						timeSlots.length > 0 &&
						timeSlots.map((slot, index) => (
							<MenuItem key={index} value={slot}>
								{slot}
							</MenuItem>
						))}
					{timeSlots && timeSlots.length == 0 && (
						<MenuItem value="">No Slots Available</MenuItem>
					)}
				</Select>
			</Box>
			<Box className={classes.buttonWrapper}>
				<Box>
					<Button
						fullWidth
						variant="contained"
						color="success"
						onClick={meetSheduled}
						disabled={!selectedTimeSlot}
						className={classes.sheduleButton}
					>
						Schedule call
					</Button>
				</Box>
				<Box>
					<GenericButton
						fullWidth
						label={'Skip to Dashboard'}
						handleClick={() => {
							trackEvent('BUTTON_CLICKED', {
								event_action: 'CLICKED',
								eventCategory: 'NAVIGATION',
								eventLabel: 'Skip to Dashboard',
								subSection: 'SCHEDULE_MEETING',
							});
							goToExternalRoute(redirectionLink, null, {}, null, false);
						}}
						className={classes.backButton}
					></GenericButton>
				</Box>
			</Box>
			{meetModal ? (
				<MeetingModal
					selectedDateTime={modalDateTime}
					open={meetModal}
					handleClose={handleClose}
					trackEvent={trackEvent}
				/>
			) : null}
			<Snackbar
				open={meetError.isOpen}
				autoHideDuration={1000}
				onClose={handleErrorClose}
				className={classes.snackBar}
			>
				<Alert
					severity={meetError.type}
					sx={{
						width: '100%',
						background: '#d32f2f',
						color: 'white!important',
						svg: {
							color: 'white',
						},
					}}
				>
					{meetError.message}
				</Alert>
			</Snackbar>
		</>
	);
}

StepFour.propTypes = {
	iconImage: PropTypes.string,
	formTitle: PropTypes.string,
	formDescription: PropTypes.string,
	selectDateLabel: PropTypes.string,
	selectTimeLabel: PropTypes.string,
	onModalClose: PropTypes.func,
	userResponse: PropTypes.object,
	trackEvent: PropTypes.func.isRequired,
};
