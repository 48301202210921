import { ChevronLeft } from '@mui/icons-material';
import { Box } from '@mui/material';
import useStyles from './style';

export default function PrevArrow({ onClick, trackEvent }) {
	const { classes } = useStyles();
	return (
		<Box
			className={classes.prevArrow}
			onClick={(clickArgs) => {
				trackEvent('LEFT');
				onClick(clickArgs);
			}}
		>
			<ChevronLeft />
		</Box>
	);
}
