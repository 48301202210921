import get from 'lodash/get';
import moment from 'moment';
import { countriesIcons } from './constants';

const taskKeys = [
	'task_001',
	'task_002',
	'task_003',
	'task_004',
	'task_005',
	'task_006',
	'task_007',
];

const targetCountriesOrder = [
	'uk',
	'usa',
	'australia',
	'canada',
	'singapore',
	'ireland',
];

const getTask = (data) => ({
	name: data.task_name,
	description: data.task_description,
	date: get(data, 'sub_tasks[0].due_date')
		? moment(data.sub_tasks[0].due_date).format('Do MMM YYYY')
		: '',
	subTasks: data.sub_tasks.map((task) => ({
		name: task.subtask_name,
		key: task.subtask_key,
		description: task.subtask_description,
		date: task.due_date ? moment(task.due_date).format('Do MMM YYYY') : '',
	})),
	icon: { url: data.task_current_icon, alternativeText: data.task_name },
	loadingIcon: { url: data.task_locked_icon, alternativeText: data.task_name },
});

const getBanner = (firstTask, dueDate) => ({
	title: firstTask.banner_title,
	icon: firstTask.banner_icon
		? { url: firstTask.banner_icon, alternativeText: 'time_table_banner' }
		: null,
	dueDate: dueDate || '',
});

export const shapeJourneyData = (data) => {
	const _data = {};

	_data.tasks = taskKeys.reduce((tasks, key) => {
		if (data[key] && !data[key].is_last) tasks.push(getTask(data[key]));
		return tasks;
	}, []);

	if (data['task_001']) {
		_data.banner = getBanner(
			data['task_001'],
			_data.tasks[_data.tasks.length - 1].subTasks.slice(-1)[0].date
		);
	}

	return _data;
};

export const getTargetCountries = (globalCountries) => {
	let countryKey;
	const countries = globalCountries.reduce((data, country) => {
		countryKey = country.toLowerCase();

		if (countriesIcons[countryKey]) {
			data[countryKey] = countriesIcons[countryKey];
		}

		return data;
	}, {});

	return targetCountriesOrder.reduce((data, key) => {
		if (countries[key]) data.push(countries[key]);
		return data;
	}, []);
};

export const getTargetIntakes = (globalIntakes) => {
	const now = moment(),
		excludeIntakesTillMoment = moment()
			.set('month', now.month() + 3)
			.endOf('month'),
		data = [];
	let momentOfStartOfIntake, currentIntakeMonth, currentIntakeYear;

	for (let i = 0; i < globalIntakes.length && data.length <= 2; i++) {
		[currentIntakeMonth, currentIntakeYear] = globalIntakes[i].split(' ');
		momentOfStartOfIntake = moment()
			.month(currentIntakeMonth || '')
			.year(currentIntakeYear || now.year())
			.startOf('month');

		if (momentOfStartOfIntake.isAfter(excludeIntakesTillMoment)) {
			data.push(globalIntakes[i]);
		}
	}

	return data;
};

export const getEducationGapIntakes = (globalEducationGap) => {
	return globalEducationGap;
};

export const textVal = {
	scrollId: 'LiveCouncillingPlug',
};

export const Step = (step, allProps) => {
	switch (step) {
		case 1:
			return {
				targetCountryLabel: allProps.targetCountryLabel,
				targetIntakeLabel: allProps.targetIntakeLabel,
				testScoreLabel: allProps.testScoreLabel,
				testNameLabel: allProps.testNameLabel,
				testTakenLabel: allProps.testTakenLabel,
				cardList: {
					...allProps.cardList[0],
					width: '400px',
					height: '300px',
				},
			};
		case 2:
			return {
				highestLevelEducationLabel: allProps.highestLevelEducationLabel,
				educationScoreLabel: allProps.educationScoreLabel,
				gapEducationLabel: allProps.gapEducationLabel,
				educationBacklogsLabel: allProps.educationBacklogsLabel,
				cardList: {
					...allProps.cardList[1],
					width: '280px',
					height: '300px',
				},
			};
		case 3:
			return {
				firstNameLabel: allProps.firstNameLabel,
				lastNameLabel: allProps.lastNameLabel,
				emailLabel: allProps.emailLabel,
				cityLabel: allProps.cityLabel,
				mobileNumberLabel: allProps.mobileNumberLabel,
				cardList: {
					...allProps.cardList[2],
					width: '280px',
					height: '300px',
				},
			};
		case 4:
			return {
				selectDateLabel: allProps.selectDateLabel,
				selectTimeLabel: allProps.selectTimeLabel,
				cardList: {
					...allProps.cardList[3],
					width: '280px',
					height: '300px',
				},
			};
	}
};

export const getEducationScore = (educationHistory) => {
	switch (get(educationHistory, 'highest_education_level')) {
		case 'PhD':
			return get(educationHistory, 'phd.gpa_or_percentage');
		case 'Masters':
			return get(educationHistory, 'masters.gpa_or_percentage');
		case '4-Year Bachelor Degree':
			return get(educationHistory, 'graduation.gpa_or_percentage');
		case '3-Year Bachelor Degree':
			return get(educationHistory, 'graduation.gpa_or_percentage');
		case 'Certificate or Diploma':
			return get(educationHistory, 'graduation.gpa_or_percentage');
		case '12th or equivalent':
			return get(educationHistory, 'grade_12_or_equivalent.gpa_or_percentage');
		case '10th or equivalent':
			return get(educationHistory, 'grade_10_or_equivalent.gpa_or_percentage');
		default:
			return null;
	}
};
