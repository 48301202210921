import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import useStyles from './style';

const wrapperNameMap = {
	COMPONENT_WRAPPER: 'componentWrapper',
	SLIDER_WRAPPER: 'sliderWrapper',
};

const SimpleSlider = ({ children, settings, ...props }) => {
	const { classes } = useStyles();

	const getClassValue = (componentIndex, wrapperType) => {
		switch (wrapperType) {
			case wrapperNameMap.COMPONENT_WRAPPER: {
				let className =
					props.activeComponentIndex === componentIndex
						? props.activeClassName || ''
						: props.inActiveClassName || '';
				return `${classes.componentWrap} ${className}`;
			}

			case wrapperNameMap.SLIDER_WRAPPER:
				return props.activeComponentIndex === componentIndex ||
					!settings.focusOnSelect
					? classes.slideWrapper
					: `${classes.slideWrapper} ${classes.cursorPointer}`;

			default:
				return '';
		}
	};

	return (
		<Slider {...settings}>
			{children.map((Component, idx) => (
				<Box key={idx} className={classes.slideContainer}>
					<Box className={getClassValue(idx, wrapperNameMap.SLIDER_WRAPPER)}>
						<Box
							className={getClassValue(idx, wrapperNameMap.COMPONENT_WRAPPER)}
						>
							{Component}
						</Box>
					</Box>
				</Box>
			))}
		</Slider>
	);
};

export default SimpleSlider;

SimpleSlider.propTypes = {
	children: PropTypes.array.isRequired,
	settings: PropTypes.object.isRequired,
};
SimpleSlider.defaultProps = {};
