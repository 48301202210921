export const validate = (
	username,
	otp,
	validateOTP,
	error,
	setError,
	errorMessages
) => {
	let validations = {
		username: '',
		otp: '',
		firstName: '',
		lastName: '',
		email: '',
		city: '',
	};
	if (username.length === 0)
		validations.username = 'Please Enter Your Mobile Number';
	if (username.length > 10 || username.length < 10)
		validations.username = 'Please Enter Valid Mobile Number';
	if (validateOTP && otp.length !== 6)
		validations.otp = 'Please Enter The 6 Digit OTP Sent To You';
	if (error.firstName && error.firstName.length !== 0) {
		validations.firstName = errorMessages.firstName;
		setError({ ...error, firstName: validations.firstName });
	}
	if (error.lastName && error.lastName.length !== 0) {
		validations.lastName = errorMessages.lastName;
		setError({ ...error, lastName: validations.lastName });
	}
	if (error.email && error.email.length !== 0) {
		validations.email = errorMessages.email;
		setError({ ...error, email: validations.email });
	}
	if (error.city && error.city.length !== 0) {
		validations.city = errorMessages.city;
		setError({ ...error, city: validations.city });
	}
	return validations;
};
