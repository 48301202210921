// Material
import { makeStyles } from 'tss-react/mui';

// Styles
const useStyles = makeStyles()((theme) => ({
	nextArrow: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		right: '-25px',
		zIndex: 100,
		cursor: 'pointer',
		fontSize: '2rem',
		[theme.breakpoints.down('sm')]: {
			right: '-15px',
		},
	},
}));

export default useStyles;
