import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()((theme) => ({
	formProfileCard: {
		textAlign: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '661px',
		height: '284px',
		borderRadius: '12px',
		background: '#FFF',
		boxShadow: 24,
		p: 4,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	questionWrap: {
		'& > * + *': {
			marginTop: '24px',
			[theme.breakpoints.down('sm')]: {
				marginTop: '16px',
			},
		},
	},
	questionLabel: {
		...theme.typography.h5,
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px',
		},
	},
	modalTitle: {
		...theme.typography.h6,
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px',
		},
	},
	formDescription: {
		marginTop: '16px',
		color: 'rgba(109, 109, 109, 1)',
		fontWeight: '500',
	},
	backButton: {
		color: '#6D6D6D',
		fontSize: '16px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
		},
	},
}));
