import PropTypes from 'prop-types';
// Components
import CenterFocusSlider from './partials/centerFocusSlider';
import NextArrow from './partials/nextArrow';
import PrevArrow from './partials/previousArrow';
import SimpleSlider from './partials/simpleSlider';
// material
import { Box } from '@mui/material';
// Utils
import * as eventActions from '@/utils/analytics';
import { getSliderSetting } from './util';

// styles
import useStyles from './style';

const ComponentSlider = React.forwardRef(function ComponentSlider(
	{ children, activeClassName, inActiveClassName, ...props },
	ref
) {
	let settings = getSliderSetting({ ...props, ref }, children.length);
	const { classes } = useStyles(props);

	const [activeComponentIndex, setActiveComponentIndex] = React.useState(
		settings.initialSlide
	);
	settings.beforeChange = (current, next) => setActiveComponentIndex(next);

	const trackEvent = (direction) => {
		eventActions.trackEvent('CAROUSEL', {
			...props.analyticsProps,
			eventCategory: 'USER_EVENT',
			eventLabel: direction + '_ARROW',
			eventAction: direction,
			total: children.length,
			currentIndex: activeComponentIndex,
			subSection:
				props.analyticsPropsSubSections.length > 0 &&
				props.analyticsPropsSubSections[activeComponentIndex],
		});
	};

	settings.nextArrow = <props.nextArrow onClick trackEvent={trackEvent} />;
	settings.prevArrow = <props.prevArrow onClick trackEvent={trackEvent} />;

	let sliderProps = {
		settings,
		activeComponentIndex,
		activeClassName,
		inActiveClassName,
		shrinkScale: props.shrinkScale,
	};
	let SliderComponent = props.centerFocusedSlider
		? CenterFocusSlider
		: SimpleSlider;

	return (
		<Box className={classes.sliderWrap}>
			<SliderComponent {...sliderProps}>{children}</SliderComponent>
		</Box>
	);
});

export default ComponentSlider;

ComponentSlider.propTypes = {
	activeClassName: PropTypes.string,
	centerFocusedSlider: PropTypes.bool,
	centerMode: PropTypes.bool,
	focusOnSelect: PropTypes.bool,
	dots: PropTypes.bool,
	speed: PropTypes.number,
	slidesToShow: PropTypes.number,
	slidesToScroll: PropTypes.number,
	shrinkScale: PropTypes.string,
	autoplay: PropTypes.bool,
	autoplaySpeed: PropTypes.number,
	arrows: PropTypes.bool,
	initialSlide: PropTypes.number,
	nextArrow: PropTypes.elementType,
	prevArrow: PropTypes.elementType,
	analyticsProps: PropTypes.object,
	analyticsPropsSubSections: PropTypes.array,
};

ComponentSlider.defaultProps = {
	centerFocusedSlider: false,
	centerMode: true,
	focusOnSelect: true,
	dots: false,
	speed: 300,
	slidesToShow: 3,
	slidesToScroll: 1,
	shrinkScale: '0.5',
	autoplay: false,
	autoplaySpeed: 2000,
	initialSlide: 0,
	nextArrow: NextArrow,
	prevArrow: PrevArrow,
	analyticsProps: {},
	analyticsPropsSubSections: [],
};
