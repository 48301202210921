import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	liveCounsellingSection: {
		minHeight: '500px',
		height: 'auto',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			'&> * + *': {
				marginTop: '32px',
			},
		},
	},
	titleSection: {
		width: '40%',
		borderRight: '2px solid #F16621',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			borderRight: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			'&> * + *': {
				marginLeft: '24px',
			},
		},
	},
	titleInnerSection: {
		borderRadius: '390px',
		background:
			'linear-gradient(180deg, rgba(245, 106, 32, 0.15) 0%, rgba(245, 211, 32, 0.15) 100%)',
		width: '390px',
		height: '390px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		filter: 'blur(67.08860778808594px)',
		position: 'absolute',
	},

	imageSection: {
		borderRadius: '276px',
		// backgroundColor: '#FFF',
		filter: `blur(25px)`,
		width: '276px',
		height: '276px',
		backgroundColor: 'rgb(0,0,0)',
	},
	profilePic: {
		'& img': {
			transition: 'all 3s ease-in-out',
			WebkitTransition: 'all 3s ease-in-out',
			MozTransition: 'all 3s ease-in-out',
		},
	},

	filterInnerSection: {
		width: '55%',
		'&> * + *': {
			marginTop: '53px',
			[theme.breakpoints.down('md')]: {
				marginTop: '35px',
			},
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	questionWrap: {
		'& > * + *': {
			marginTop: '24px',
			[theme.breakpoints.down('sm')]: {
				marginTop: '16px',
			},
		},
	},
	questionLabel: {
		...theme.typography.h5,
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px',
		},
	},
	profileContainer: {
		position: 'relative',
		width: '390px',
		height: '390px',
	},
	mobileTitleSection: {
		display: 'flex',
	},
	mobileTitle: {
		display: 'flex',
		alignItems: 'center',
		h2: {
			marginLeft: '10px',
			height: '62PX',
			[theme.breakpoints.down('md')]: {
				marginLeft: '10px',
				height: '62PX',
				marginTop: '0px',
			},
		},
	},
	titleWrap: {
		marginBottom: theme.spacing(4),
	},
	styled: {
		boxShadow: '0px 0px 60px rgb(254 200 152 / 87%)',
		padding: '40px',
		borderRadius: '20px',
		[theme.breakpoints.down('xl')]: {
			padding: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '14px',
		},
	},
}));

export default useStyles;
