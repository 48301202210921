import { createUser, getCityPlaces } from '@/api';
import get from 'lodash/get';
import PropTypes from 'prop-types';
//actions
import {
	goToExternalRoute,
	goToRoute,
	isExternalUrl,
	loginUser,
	sendLoginOTP,
	userGetAndStore,
} from '@/store/actions';

//components
import FormSection from '@/components/shared/liveCounsellingSection/partials/formSection';
import {
	Autocomplete,
	Box,
	FormHelperText,
	TextField,
	Typography,
} from '@mui/material';

//utils
import constants from '@/constants';
import { getOTPPayload } from '@/utils/auth';
import { getLeadVerifiedEventProps, getRecordPayload } from '@/utils/form';
import redirectToPlatform from '@/utils/redirect-to-platform';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//styles
import { useStyles } from './style';

//constants
const RESENT_OTP_TIME = 30;

export default function StepThree({
	setStep,
	firstNameLabel,
	emailLabel,
	cityLabel,
	mobileNumberLabel,
	handleEmailChange,
	lastNameLabel,
	cities,
	error,
	firstName,
	lastName,
	city,
	email,
	firstNameChange,
	lastNameChange,
	citySelect,
	initialPayload,
	setError,
	mobileNumber,
	setCities,
	setIsScroll,
	trackEvent,
	...props
}) {
	const last10Digits =
		mobileNumber && mobileNumber.substring(mobileNumber.length - 10);
	let now = new Date();
	const currentDate = moment();
	const dispatch = useDispatch();
	const currentHour = now.getHours();
	let currentMinute = now.getMinutes();
	const [curTime, setCurTime] = React.useState(RESENT_OTP_TIME);
	const [selectedDate] = useState(currentDate);
	const [isResendOtp, setResendOTP] = React.useState();
	const [loading, setLoading] = React.useState(false);
	const [edit, setEdit] = React.useState(true);
	const [thisUserData, setThisUserData] = React.useState({
		otpSent: false,
		phoneNumber: '',
		redirectionLink: '',
	});
	const { classes } = useStyles();
	const [searchVal, setSearchVal] = useState('');
	const stateData = useSelector((state) => state.global.data);
	const location = useSelector((state) => state.router.location);
	const authConstants = useSelector((state) =>
		get(state, 'cmsGlobalData.data.globalFormConfig.authConstants')
	);

	function debonceSearch() {
		getCityPlaces({ search: searchVal }).then((res) => {
			let acc = [];
			res.data.map((value) => {
				acc = [...acc, value.name];
			});
			setCities(acc);
		});
	}
	React.useEffect(() => {
		let timeoutId;
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			if (searchVal) {
				debonceSearch();
			} else {
				setCities(stateData.cities);
			}
		}, 300);
	}, [searchVal]);

	React.useEffect(() => {
		if (thisUserData.otpSent) {
			setEdit(thisUserData.otpSent);
		} else {
			setEdit(false);
		}
	}, [thisUserData]);

	function generateTimeSlots() {
		const slots = [];
		let currentHours = currentHour;

		const isDateMatchSelectedDate = () => {
			if (selectedDate) return selectedDate.isSame(currentDate, 'day');
		};

		if (selectedDate && selectedDate.day() === 0) {
			return [];
		}

		if (isDateMatchSelectedDate()) {
			if (currentHour < 10) {
				currentHours = 10;
				currentMinute = 0;
			}
		} else {
			currentHours = 10;
			currentMinute = 0;
		}

		for (let hour = currentHours; hour <= 19; hour++) {
			const startMinute =
				hour === currentHours ? Math.ceil(currentMinute / 30) * 30 : 0;
			for (let minute = startMinute; minute < 60; minute += 30) {
				if (hour === 19 && minute === 30) {
					break;
				}
				const formattedHour = hour % 12 || 12;
				const startMinuteStr = String(minute).padStart(2, '0');
				const endHour = String(
					hour + ((minute + 30 >= 60 ? 1 : 0) % 24)
				).padStart(2, '0');
				const formattedEndHour = endHour % 12 || 12;
				const endMinute = String((minute + 30) % 60).padStart(2, '0');
				const timeSlot = `${formattedHour}:${startMinuteStr}-${formattedEndHour}:${endMinute}`;
				slots.push(timeSlot);
			}
		}
		return slots;
	}
	function isOutsideOfficeHours(slots) {
		const slotsNotAvailable = slots && slots.length;
		const currentDate = new Date();
		const currentTime = currentDate.getHours();
		const isTimeNotBetween10to19 = currentTime < 10 || currentTime >= 19;

		if (!slotsNotAvailable || isTimeNotBetween10to19) {
			return true;
		} else {
			return false;
		}
	}

	const onSubmit = async (values) => {
		setLoading(true);
		let loginResult = await dispatch(
			loginUser({ username: `+91${values.username}`, otp: values.otp })
		);
		const outsideOfficeHours = isOutsideOfficeHours(generateTimeSlots());
		if (loginResult.success) {
			trackEvent('MOBILE_VERIFY_SUCCESS', {
				eventAction: 'MOBILE_VERIFY_SUCCESS',
				eventLabel: 'Mobile number verified',
				eventCategory: 'LEAD',
				subSection: 'BASIC_DETAILS',
			});

			const leadVerifiedEventProps = getLeadVerifiedEventProps(
				'Live counselling plug'
			);
			trackEvent(leadVerifiedEventProps.eventName, leadVerifiedEventProps);

			const redirectionLink = thisUserData.redirectionLink;
			if (outsideOfficeHours) {
				dispatch(userGetAndStore());
				setStep(4);
				setIsScroll(true);
			} else if (redirectionLink) {
				if (isExternalUrl(redirectionLink)) {
					goToExternalRoute(redirectionLink, null, {}, null, false);
				} else {
					dispatch(goToRoute(redirectionLink));
				}
			} else {
				redirectToPlatform(loginResult.userInfo);
			}
		}
		setLoading(false);
	};

	const createRecord = (phoneNumber) => {
		const payload = {
			email: initialPayload.email,
			first_name: initialPayload.firstName,
			last_name: initialPayload.lastName,
			city: initialPayload.city,
			is_journey_completed: true,
			highest_education: initialPayload.highestLevelOfEducation,
			percentage_or_gpa: initialPayload.educationScore,
			backlogs: initialPayload.educationBacklogs,
			gap: initialPayload.educationGap,
			target_country: initialPayload.targetCountry,
			mobile: phoneNumber,
			target_intake: initialPayload.aimmingYear,
		};

		if (initialPayload.givenTest === 'Yes') {
			payload.is_tests_opted = initialPayload.givenTest;
			if (initialPayload.testName === 'IELTS') {
				const testName = 'ielts_score';
				payload.exam_name = initialPayload.testName;
				payload[testName] = initialPayload.testScore;
			}
			if (initialPayload.testName === 'TOEFL') {
				const testName = 'toefl_score';
				payload.exam_name = initialPayload.testName;
				payload[testName] = initialPayload.testScore;
			}
			if (initialPayload.testName === 'PTE') {
				const testName = 'pte_score';
				payload.exam_name = initialPayload.testName;
				payload[testName] = initialPayload.testScore;
			}
			if (initialPayload.testName === 'GRE') {
				const testName = 'gre_score';
				payload.exam_name = initialPayload.testName;
				payload[testName] = initialPayload.testScore;
			}
			if (initialPayload.testName === 'SAT') {
				const testName = 'sat_score';
				payload.exam_name = initialPayload.testName;
				payload[testName] = initialPayload.testScore;
			}
			if (initialPayload.testName === 'DUOLINGO') {
				const testName = 'duolingo_score';
				payload.exam_name = initialPayload.testName;
				payload[testName] = initialPayload.testScore;
			}
			if (initialPayload.testName === 'GMAT') {
				const testName = 'gmat_score';
				payload.exam_name = initialPayload.testName;
				payload[testName] = initialPayload.testScore;
			}
		}

		return getRecordPayload(
			payload,
			props.constants,
			props.formPageConstants,
			true,
			location
		).then((data) => createUser(data));
	};

	const sendOTP = (phoneNumber, lastName, firstName, city, email, resend) => {
		setLoading(true);
		createRecord(phoneNumber)
			.then(async () => {
				let sendOtpResult;
				if (
					error.firstName === '' &&
					error.lastName === '' &&
					error.email === '' &&
					error.city === '' &&
					lastName &&
					firstName &&
					city &&
					email
				) {
					const data = getOTPPayload(
						phoneNumber,
						resend,
						authConstants,
						location
					);
					sendOtpResult = await dispatch(sendLoginOTP(data));
					if (sendOtpResult.success) {
						setThisUserData({
							phoneNumber,
							otpSent: true,
							redirectionLink: `${constants.studentPlatformUrl}talk-to-counsellor-form?onboarding_type=counselling`,
						});
						setResendOTP(true);
						setCurTime(RESENT_OTP_TIME);
					}
					setLoading(false);
				}
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const handleEdit = () => {
		setThisUserData({ ...thisUserData, otpSent: false });
		setEdit(!edit);
	};

	return (
		<>
			<Box className={classes.questionContainer}>
				<Box className={`${classes.questionWrap} ${classes.firstName}`}>
					<Typography className={classes.questionLabel}>
						{firstNameLabel}
					</Typography>
					<TextField
						id="outlined-basic"
						variant="outlined"
						value={firstName}
						className={classes.nameField}
						onChange={(e) => firstNameChange(e)}
						error={error.firstName !== ''}
						helperText={error.firstName}
						disabled={edit}
					/>
				</Box>
				<Box className={`${classes.questionWrap} ${classes.lastNameContainer}`}>
					<Typography className={classes.questionLabel}>
						{lastNameLabel}
					</Typography>

					<TextField
						id="outlined-basic"
						variant="outlined"
						className={`${classes.nameField} ${classes.lastName}`}
						value={lastName}
						onChange={(e) => lastNameChange(e)}
						error={error.lastName !== ''}
						helperText={error.lastName}
						disabled={edit}
					/>
				</Box>
			</Box>
			<Box className={classes.questionContainer}>
				<Box className={`${classes.questionWrap} ${classes.firstName}`}>
					<Typography className={classes.questionLabel}>
						{emailLabel}
					</Typography>
					<TextField
						id="outlined-basic"
						variant="outlined"
						className={classes.nameField}
						value={email}
						onChange={(e) => handleEmailChange(e)}
						error={error.email !== ''}
						helperText={error.email}
						disabled={edit}
					/>
				</Box>
				<Box className={`${classes.questionWrap} ${classes.lastNameContainer}`}>
					<Typography className={classes.questionLabel}>{cityLabel}</Typography>
					<Autocomplete
						value={city}
						onChange={(_, newValue) => {
							citySelect(newValue);
						}}
						error={error}
						helperText={error.city}
						disabled={edit}
						options={cities}
						variant="outlined"
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								value={city}
								className={`${classes.nameField} ${classes.lastName} ${classes.customAutocomplete}`}
								onChange={(event) => {
									setSearchVal(event.target.value);
								}}
							/>
						)}
					/>
					<FormHelperText
						style={{
							color: 'rgb(211, 47, 47)',
							position: 'relative',
							top: '-20px',
							left: '17px',
						}}
					>
						{error.city ? error.city : ''}
					</FormHelperText>
				</Box>
			</Box>

			<Box style={{ marginTop: '25px' }}>
				<FormSection
					onSubmit={onSubmit}
					otpSent={thisUserData.otpSent}
					sendOTP={sendOTP}
					curTime={curTime}
					setCurTime={setCurTime}
					isResendOtp={isResendOtp}
					setResendOTP={setResendOTP}
					phoneNumber={thisUserData.phoneNumber}
					mobileNumberLabel={mobileNumberLabel}
					setStep={setStep}
					error={error}
					setError={setError}
					initialPayload={initialPayload}
					lastName={lastName}
					firstName={firstName}
					city={city}
					email={email}
					edit={edit}
					handleEdit={handleEdit}
					last10Digits={last10Digits}
					trackEvent={trackEvent}
					loading={loading}
				/>
			</Box>
		</>
	);
}

StepThree.propTypes = {
	targetCountryLabel: PropTypes.string,
	handleCountryClicked: PropTypes.func,
	error: PropTypes.object,
	setStep: PropTypes.any,
	firstNameLabel: PropTypes.string,
	emailLabel: PropTypes.string,
	cityLabel: PropTypes.string,
	mobileNumberLabel: PropTypes.string,
	handleEmailChange: PropTypes.func,
	lastNameLabel: PropTypes.string,
	cities: PropTypes.array,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	city: PropTypes.string,
	email: PropTypes.string,
	firstNameChange: PropTypes.func,
	lastNameChange: PropTypes.func,
	citySelect: PropTypes.func,
	initialPayload: PropTypes.object,
	setError: PropTypes.any,
	trackEvent: PropTypes.func.isRequired,
	constants: PropTypes.object,
	formPageConstants: PropTypes.object,
};
