import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()((theme) => ({
	formProfileCard: {
		textAlign: 'center',
	},
	questionWrap: {
		'& > * + *': {
			marginTop: '24px',
			[theme.breakpoints.down('sm')]: {
				marginTop: '16px',
			},
		},
	},
	questionLabel: {
		...theme.typography.h5,
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px',
		},
	},
	formTitle: {
		...theme.typography.h5,
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px',
		},
	},
	formDescription: {
		marginTop: '16px',
		color: 'rgba(109, 109, 109, 1)',
		fontWeight: '500',
	},
	snackBar: {
		left: 'auto!important',
		right: '20px!important',
		color: 'white!important',
	},
}));
