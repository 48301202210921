import PropTypes from 'prop-types';

// components
import ImageComponent from '@/components/widgets/image';
import { LinkWraper } from './linkWrapper';

// material
import { Box, Typography } from '@mui/material';

// style
import useStyles from './style';

export default function ImageCard({
	image,
	label,
	link,
	imageShape,
	...otherProps
}) {
	const { classes } = useStyles(otherProps);

	return (
		<Box>
			<LinkWraper link={link} className={classes[`${imageShape}ImageWrap`]}>
				<ImageComponent
					src={image.url}
					alt={image.alternativeText}
					layout="fill"
					objectFit="contain"
					className={imageShape === 'logo' ? classes.logo : ''}
				/>
			</LinkWraper>

			{label && (
				<Box className={classes.labelWrap}>
					<Typography className={classes.label}>{label}</Typography>
				</Box>
			)}
		</Box>
	);
}

ImageCard.propTypes = {
	image: PropTypes.object.isRequired,
	label: PropTypes.string,
	link: PropTypes.string,
	imageShape: PropTypes.string,
	imageWidth: PropTypes.string,
	imageHeight: PropTypes.string,
};
ImageCard.defaultProps = {
	imageShape: 'rectangle',
};
