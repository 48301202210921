import PropTypes from 'prop-types';

// material
import { TextField } from '@mui/material';

// styles
import useStyles from './style';

export default function SingleOTPInput({ focus, autoFocus, ...props }) {
	const { classes } = useStyles();
	const inputRef = React.useRef(null);

	React.useEffect(() => {
		if (inputRef.current) {
			if (focus && autoFocus) inputRef.current.focus();
		}
	}, [autoFocus, focus]);

	return (
		<TextField
			{...props}
			inputRef={inputRef}
			inputProps={{ className: classes.textCenter }}
			InputProps={{ className: classes.inputField }}
		/>
	);
}

SingleOTPInput.propTypes = {
	type: PropTypes.string.isRequired,
	focus: PropTypes.bool,
	autoFocus: PropTypes.bool,
};

SingleOTPInput.defaultProps = {
	type: 'number',
	focus: false,
	autoFocus: false,
};
