import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import useStyles from './style';

const wrapperNameMap = {
	COMPONENT_WRAPPER: 'componentWrapper',
	SLIDER_WRAPPER: 'sliderWrapper',
	SLIDE: 'slide',
};

const CenterFocusSlider = ({ children, settings, ...props }) => {
	const { classes } = useStyles({ shrinkScale: props.shrinkScale });

	const getClassValue = (componentIndex, wrapperType) => {
		switch (wrapperType) {
			case wrapperNameMap.COMPONENT_WRAPPER:
				return props.activeComponentIndex === componentIndex
					? props.activeClassName || ''
					: props.inActiveClassName || '';

			case wrapperNameMap.SLIDER_WRAPPER:
				return props.activeComponentIndex === componentIndex
					? classes.slideWrapper
					: `${classes.slideWrapper} ${classes.cursorPointer}`;

			case wrapperNameMap.SLIDE:
				return props.activeComponentIndex === componentIndex
					? classes.activeSlide
					: classes.slide;

			default:
				return '';
		}
	};

	return (
		<Slider {...settings}>
			{children.map((Component, idx) => (
				<Box className={getClassValue(idx, wrapperNameMap.SLIDE)} key={idx}>
					<Box className={getClassValue(idx, wrapperNameMap.SLIDER_WRAPPER)}>
						<Box
							className={getClassValue(idx, wrapperNameMap.COMPONENT_WRAPPER)}
						>
							{Component}
						</Box>
					</Box>
				</Box>
			))}
		</Slider>
	);
};

export default CenterFocusSlider;

CenterFocusSlider.propTypes = {
	children: PropTypes.array.isRequired,
	settings: PropTypes.object.isRequired,
};

CenterFocusSlider.defaultProps = {};
