import PropTypes from 'prop-types';

// components
import SingleInput from '../singleInput';

// material
import { Box, FormHelperText } from '@mui/material';

// styles
import useStyles from './style';

export function OTPInputField({ length, isNumberInput, ...props }) {
	const { classes } = useStyles();

	const [activeInput, setActiveInput] = React.useState(0);
	const [otpValues, setOTPValues] = React.useState(() =>
		[...new Array(length)].map((_, idx) => props.value[idx] || '')
	);

	const focusInput = (inputIndex) => {
		const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
		setActiveInput(selectedIndex);
	};

	const handleOnFocus = (index) => () => focusInput(index);

	const onBlur = () => setActiveInput(-1);

	const handleOtpChange = (otp) => props.onChangeOTP(otp.join(''));

	const getRightValue = (str) => {
		let changedValue = str;

		if (!isNumberInput || !changedValue) {
			return changedValue;
		}

		return Number(changedValue) >= 0 ? changedValue : '';
	};

	const changeCodeAtFocus = (str) => {
		const updatedOTPValues = [...otpValues];
		updatedOTPValues[activeInput] = str[0] || '';
		setOTPValues(updatedOTPValues);
		handleOtpChange(updatedOTPValues);
	};

	const handleOnChange = (e) => {
		const val = getRightValue(e.currentTarget.value);
		if (!val) {
			e.preventDefault();
			return;
		}
		changeCodeAtFocus(val);
		focusInput(activeInput + 1);
	};

	const handleOnKeyDown = (e) => {
		const pressedKey = e.key;

		switch (pressedKey) {
			case 'Backspace':
			case 'Delete': {
				e.preventDefault();
				if (otpValues[activeInput]) {
					changeCodeAtFocus('');
				} else {
					focusInput(activeInput - 1);
				}
				break;
			}
			case 'ArrowLeft': {
				e.preventDefault();
				focusInput(activeInput - 1);
				break;
			}
			case 'ArrowRight': {
				e.preventDefault();
				focusInput(activeInput + 1);
				break;
			}
			default: {
				if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
					e.preventDefault();
				}

				break;
			}
		}
	};

	const handleOnPaste = (e) => {
		e.preventDefault();
		const pastedData = e.clipboardData
			.getData('text/plain')
			.trim()
			.slice(0, length - activeInput)
			.split('');
		if (pastedData) {
			let nextFocusIndex = 0;
			const updatedOTPValues = [...otpValues];
			updatedOTPValues.forEach((val, index) => {
				if (index >= activeInput) {
					const changedValue = getRightValue(pastedData.shift() || val);
					if (changedValue) {
						updatedOTPValues[index] = changedValue;
						nextFocusIndex = index;
					}
				}
			});
			setOTPValues(updatedOTPValues);
			handleOtpChange(updatedOTPValues);
			setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
		}
	};

	return (
		<>
			<Box className={props.className}>
				{Array(length)
					.fill('')
					.map((_, index) => (
						<Box key={index} className={classes.inputFieldWrap}>
							<SingleInput
								type={isNumberInput ? 'number' : 'text'}
								focus={activeInput === index}
								value={otpValues && otpValues[index]}
								autoFocus={props.autoFocus}
								onFocus={handleOnFocus(index)}
								onChange={handleOnChange}
								onKeyDown={handleOnKeyDown}
								onBlur={onBlur}
								onPaste={handleOnPaste}
								className={props.inputClassName}
								disabled={props.disabled}
								required={true}
								error={props.error}
							/>
						</Box>
					))}
			</Box>
			{props.error && (
				<FormHelperText error classes={{ root: classes.errorText }}>
					{props.helperText}
				</FormHelperText>
			)}
		</>
	);
}

export default React.memo(OTPInputField);

OTPInputField.propTypes = {
	length: PropTypes.number,
	value: PropTypes.string,
	isNumberInput: PropTypes.bool,
	disabled: PropTypes.bool,
	autoFocus: PropTypes.bool,
	onChangeOTP: PropTypes.func.isRequired,
	inputClassName: PropTypes.string,
};

OTPInputField.defaultProps = {
	length: 6,
	value: '',
	isNumberInput: true,
	disabled: false,
	autoFocus: false,
};
