export const getSliderSetting = (config, noOfComponent) => {
	const settings = {
		...config,
		infinite: true,
		swipeToSlide: true,
		centerMode: !!config.centerFocusedSlider,
		nextArrow: (
			<config.nextArrow onClick analyticsProps={config.analyticsProps} />
		),
		prevArrow: (
			<config.prevArrow onClick analyticsProps={config.analyticsProps} />
		),
	};

	if (config.slidesToShow > noOfComponent) {
		settings.slidesToShow = noOfComponent;
	}

	if (config.centerFocusedSlider) {
		settings.className = 'center';
		settings.centerPadding = '0';
	} else if (config.startWithCenterItem) {
		settings.initialSlide = Math.floor(config.slidesToShow / 2);
	}

	return settings;
};
