import { makeStyles } from 'tss-react/mui';
const commonButtonStyles = {
	borderRadius: '25px',
	display: 'inline-block',
	padding: '10px 27px',
	border: '1px solid #C4C4C4',
	fontSize: '16px',
	boxShadow: 'none',
};
export const useStyles = makeStyles()((theme) => ({
	questionWrap: {
		'& > * + *': {
			marginTop: '24px',
			[theme.breakpoints.down('sm')]: {
				marginTop: '16px',
			},
		},
	},
	questionLabel: {
		...theme.typography.h5,
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px',
		},
	},
	scoreField: {
		width: '100%',
		'& div': {
			borderRadius: '15px',
		},
	},
	intakeOptionWrap: {
		margin: '5px 0 0 -19px!important',
		'& > *': { margin: '19px 0 0 19px' },
		width: '100%;',
		[theme.breakpoints.down('sm')]: {
			width: '100%;',
		},
	},
	intake: {
		...commonButtonStyles,
		fontWeight: theme.typography.fontWeightMedium,
	},
	buttonWrapper: {
		marginTop: '60px',
	},
	submitButton: {
		width: '100%',
	},
	backButton: {
		width: '100%',
		color: '#6D6D6D',
	},
	educationContainer: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: { display: 'inline-block', width: '100%' },
	},
	highestEducation: { width: '100%', borderRadius: '15px' },
	levelEducation: {
		width: '48%',
		[theme.breakpoints.down('sm')]: { width: '100%' },
	},
	educationBacklogs: {
		width: '48%',
		marginLeft: '4%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginLeft: '0',
			marginTop: '35px',
		},
	},
}));
