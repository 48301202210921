import ComponentSlider from '@/components/shared/componentSlider';
import ImageCard from '@/components/shared/imageCard';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
	Box,
	Button,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

//utils
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { getTargetCountries } from '../../util';

//style
import useStyles from './style';

export default function StepOne({
	targetCountryLabel,
	targetIntakeLabel,
	selectedCountry,
	selectedIntake,
	examTaken,
	textValues,
	handleCountryClicked,
	handleIntakeClicked,
	testTakenLabel,
	handleExamTakenClick,
	testNameLabel,
	testScoreLabel,
	testName,
	testNameVal,
	handleTestNameSelect,
	handleTestScoreChange,
	testScore,
	targetIntakes,
	error,
	buttonDisable,
	setStep,
	setIsScroll,
	trackEvent,
}) {
	const { classes, cx } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	const isDesktop = useSelector((state) => state.common.isDesktop);

	const handleCountryClick = (value) => {
		handleCountryClicked(value);
	};
	const targetCountries = useSelector((state) =>
		getTargetCountries(get(state, 'global.data.countries', []))
	);
	const handleIntakeClick = (value) => {
		handleIntakeClicked(value);
	};

	return (
		<>
			<Box className={classes.questionWrap}>
				<Typography className={classes.questionLabel}>
					{targetCountryLabel}
				</Typography>
				{isMobile ? (
					<Box className={classes.countryOptionWrap}>
						{targetCountries.map((icon, index) => (
							<Box
								key={index}
								className={cx(
									classes.countryOption,
									selectedCountry === icon.name ? classes.selected : ''
								)}
								onClick={() => handleCountryClick(icon.name)}
							>
								<ImageCard
									image={icon}
									label={icon.alternativeText}
									imageWidth="55px"
									imageShape="circle"
								/>
							</Box>
						))}
					</Box>
				) : (
					<ComponentSlider
						focusOnSelect={false}
						slidesToShow={isDesktop ? 6 : 3}
						arrows={!isMobile}
						useTransform={false}
						cssEase="linear"
						accessibility={false}
						className={classes.bannerSection}
						analyticsPropsSubSections={targetCountries.map(
							(item) => item.alternativeText
						)}
						nextArrow={({ onClick, trackEvent }) => (
							<Box
								className={classes.nextArrow}
								onClick={(clickArgs) => {
									trackEvent('RIGHT');
									onClick(clickArgs);
								}}
							>
								<ChevronRight fontSize="medium" />
							</Box>
						)}
						prevArrow={({ onClick, trackEvent }) => (
							<Box
								className={classes.prevArrow}
								onClick={(clickArgs) => {
									trackEvent('LEFT');
									onClick(clickArgs);
								}}
							>
								<ChevronLeft fontSize="medium" />
							</Box>
						)}
						responsive={[
							{
								breakpoint: 1025,
								settings: { slidesToShow: 3 },
							},
						]}
					>
						{targetCountries.map((icon, index) => (
							<Box
								key={index}
								className={cx(
									classes.countryOption,
									selectedCountry === icon.name ? classes.selected : ''
								)}
								onClick={() => handleCountryClick(icon.name)}
							>
								<ImageCard
									image={icon}
									label={icon.alternativeText}
									imageWidth="55px"
									imageShape="circle"
								/>
							</Box>
						))}
					</ComponentSlider>
				)}
			</Box>
			<Box className={classes.questionWrap} id={textValues.intakeQuestionId}>
				<Typography
					color={selectedCountry ? 'inherit' : 'text.lightGrey'}
					className={classes.questionLabel}
				>
					{targetIntakeLabel}
				</Typography>
				<Box className={classes.intakeOptionWrap}>
					{targetIntakes.map((intake, index) => (
						<Button
							key={index}
							color={selectedIntake === intake ? 'primary' : 'inherit'}
							variant={selectedIntake === intake ? 'contained' : 'outlined'}
							onClick={() => handleIntakeClick(intake)}
							className={classes.intake}
							disabled={!selectedCountry}
						>
							{intake}
						</Button>
					))}
				</Box>
			</Box>
			<Box className={classes.questionWrap} id={textValues.examTakenQuestionId}>
				<Typography
					color={
						!selectedCountry || !selectedIntake ? 'text.lightGrey' : 'inherit'
					}
					className={classes.questionLabel}
				>
					{testTakenLabel}
				</Typography>
				<Box className={classes.answerButtonWrap}>
					<Button
						className={classes.answerButton}
						color={
							examTaken === textValues.examTakenValue ? 'primary' : 'inherit'
						}
						variant={
							examTaken === textValues.examTakenValue ? 'contained' : 'outlined'
						}
						onClick={() => handleExamTakenClick(textValues.examTakenValue)}
						disabled={!selectedCountry || !selectedIntake}
					>
						{textValues.examTakenValue}
					</Button>
					<Button
						className={classes.answerButton}
						color={
							examTaken === textValues.examNotTakenValue ? 'primary' : 'inherit'
						}
						variant={
							examTaken === textValues.examNotTakenValue
								? 'contained'
								: 'outlined'
						}
						onClick={() => handleExamTakenClick(textValues.examNotTakenValue)}
						disabled={!selectedCountry || !selectedIntake}
					>
						{textValues.examNotTakenValue}
					</Button>
				</Box>
			</Box>
			{selectedCountry && selectedIntake && examTaken == 'Yes' && (
				<Box style={isMobile ? null : { display: 'flex' }}>
					<Box
						className={classes.questionWrap}
						style={isMobile ? { width: '100%' } : { width: '60%' }}
					>
						<Typography className={classes.questionLabel}>
							{testNameLabel}
						</Typography>
						<Select
							value={testNameVal}
							onChange={(e) => {
								handleTestNameSelect(e.target.value);
							}}
							style={
								isMobile
									? { width: '100%', borderRadius: '15px' }
									: { width: '90%', borderRadius: '15px' }
							}
						>
							{testName ? (
								testName.map((name, key) => (
									<MenuItem key={key} value={name}>
										{name}
									</MenuItem>
								))
							) : (
								<MenuItem value="">
									<em>No Options</em>
								</MenuItem>
							)}
						</Select>
					</Box>
					<Box
						className={classes.questionWrap}
						style={
							isMobile
								? { width: '100%', marginTop: '35px' }
								: { marginLeft: '1%', width: '38%' }
						}
					>
						<Typography className={classes.questionLabel}>
							{testScoreLabel}
						</Typography>

						<TextField
							onChange={(e) => handleTestScoreChange(e.target.value)}
							value={testScore}
							id="outlined-basic"
							variant="outlined"
							type="number"
							className={classes.scoreField}
							error={error.testScore !== ''}
							helperText={error.testScore}
						/>
					</Box>
				</Box>
			)}
			<Button
				variant="contained"
				color="success"
				onClick={() => {
					setStep(2);
					setIsScroll(true);
					trackEvent('BUTTON_CLICKED', {
						event_action: 'CLICKED',
						eventCategory: 'USER_EVENT',
						eventLabel: 'Submit',
						subSection: 'TARGET_DETAILS',
					});
				}}
				label={'Submit'}
				fullWidth
				className={classes.submitButton}
				disabled={buttonDisable}
			>
				Submit
			</Button>
		</>
	);
}

StepOne.propTypes = {
	targetCountryLabel: PropTypes.string,
	targetIntakeLabel: PropTypes.string,
	selectedCountry: PropTypes.string,
	selectedIntake: PropTypes.string,
	examTaken: PropTypes.string,
	textValues: PropTypes.object,
	handleCountryClicked: PropTypes.func,
	handleIntakeClicked: PropTypes.func,
	testTakenLabel: PropTypes.string,
	handleExamTakenClick: PropTypes.func,
	testNameLabel: PropTypes.string,
	testScoreLabel: PropTypes.string,
	testName: PropTypes.array,
	testNameVal: PropTypes.string,
	handleTestNameSelect: PropTypes.func,
	handleTestScoreChange: PropTypes.func,
	testScore: PropTypes.string,
	error: PropTypes.object,
	trackEvent: PropTypes.func.isRequired,
};
