import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
//component
import { Box, Button, Modal, Typography } from '@mui/material';
import Image from 'next/image';

//utils
import { useSelector } from 'react-redux';

//image
import calenderTime from '@/assets/icons/calender-clock.png';

//constant
import { redirectionLink } from '@/components/shared/liveCounsellingSection/constants';

//action
import { goToExternalRoute } from '@/store/actions';

//style
import { useStyles } from './style';

const MeetingModal = ({ open, handleClose, selectedDateTime, trackEvent }) => {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	const [time, setTime] = useState(null);
	const [isRunning, setIsRunning] = useState();

	useEffect(() => {
		setIsRunning(true);
		setTime(5);

		trackEvent('MEETING_MODAL_SHOWN', {
			event_action: 'OPEN',
			eventCategory: 'USER_EVENT',
			eventLabel: 'MEETING_MODAL_SHOWN',
			subSection: 'SCHEDULE_MEETING_MODAL',
		});
	}, []);

	useEffect(() => {
		let timer;
		if (isRunning && time > 0) {
			timer = setInterval(() => {
				setTime((prevTime) => prevTime - 1);
			}, 1000);
		} else if (time === 0) {
			trackEvent('BUTTON_CLICKED', {
				automatic: true,
				event_action: 'CLICKED',
				eventCategory: 'NAVIGATION',
				eventLabel: 'Redirecting to dashboard',
				subSection: 'SCHEDULE_MEETING_MODAL',
			});
			goToExternalRoute(redirectionLink, null, {}, null, false);
			setIsRunning(false);
		}
		return () => {
			clearInterval(timer);
		};
	}, [time]);

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				className={
					isMobile
						? `${classes.formProfileCard} ${classes.mobileformProfileCard}`
						: `${classes.formProfileCard}`
				}
			>
				<Box style={{ margin: '50px' }}>
					<Image
						src={calenderTime}
						alt="Picture of the author"
						width="87px"
						height="81px"
					/>
					{/* <CalenderClock /> */}
					<Box
						style={{
							marginLeft: 'auto',
							marginRight: 'auto',
							width: isMobile ? '65%' : '380px',
						}}
					>
						<Typography className={classes.modalTitle}>
							Your meeting is successfully scheduled on{' '}
							<span style={{ color: '#F56A20' }}>{selectedDateTime}</span>
						</Typography>
					</Box>

					<Typography className={classes.formDescription}>
						<Button
							onClick={() => {
								trackEvent('BUTTON_CLICKED', {
									event_action: 'CLICKED',
									eventCategory: 'NAVIGATION',
									eventLabel: 'Redirecting to dashboard',
									subSection: 'SCHEDULE_MEETING_MODAL',
								});
								goToExternalRoute(redirectionLink, null, {}, null, false);
							}}
							variant="text"
							className={classes.backButton}
						>
							{' '}
							Redirecting to dashboard 0{time}
						</Button>
					</Typography>
				</Box>
			</Box>
		</Modal>
	);
};

export default MeetingModal;

MeetingModal.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	selectedDateTime: PropTypes.string,
	trackEvent: PropTypes.func.isRequired,
};
