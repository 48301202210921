// Material
import { makeStyles } from 'tss-react/mui';

// constant
const slidesFullHeightStyles = {
	sliderWrap: {
		'& .slick-track': {
			display: 'flex !important',
		},

		'& .slick-slide': {
			height: 'inherit !important',
		},

		'& .slick-slide > div': {
			height: '100%',
		},
	},
};

const useStyles = makeStyles()((_, { vertical }) =>
	vertical ? { sliderWrap: {} } : slidesFullHeightStyles
);

export default useStyles;
