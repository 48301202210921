// Material
import { makeStyles } from 'tss-react/mui';

// Styles
const useStyles = makeStyles()((theme, { shrinkScale }) => ({
	slide: {
		transform: `scale(${shrinkScale})`,
		transition: 'transform 300ms',
		opacity: 0.5,
	},
	slideWrapper: {
		display: 'flex',
		justifyContent: 'center',
	},
	activeSlideWrapper: {
		display: 'inline-flex',
		width: '100%',
		justifyContent: 'center',
	},
	cursorPointer: {
		cursor: 'pointer',
	},
}));

export default useStyles;
