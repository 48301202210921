import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
	questionWrap: {
		'& > * + *': {
			marginTop: '24px',
			[theme.breakpoints.down('sm')]: {
				marginTop: '16px',
			},
		},
	},
	questionLabel: {
		...theme.typography.h5,
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px',
		},
	},
	nameField: {
		width: '100%',
		div: {
			borderRadius: '18px',
		},
	},
	submitButton: {
		width: '100%',
	},
	backButton: {
		width: '100%',
	},
	lastNameContainer: {
		marginLeft: '4%',
		width: '48%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginTop: '35px',
			marginLeft: '0',
		},
	},
	questionContainer: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: { display: 'inline-block', width: '100%' },
	},
	firstName: {
		width: '48%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			borderRadius: '15px',
		},
	},
	customAutocomplete: {
		'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
			padding: '3px 3px',
		},
	},
}));
