import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	inputFieldWrap: {
		display: 'inline-block',
		margin: theme.spacing(0, 1),
		[theme.breakpoints.down('sm')]: {
			margin: '0 5px',
		},
	},
	errorText: {
		textAlign: 'center',
	},
}));

export default useStyles;
