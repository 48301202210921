import PropTypes from 'prop-types';
//components
import {
	Box,
	Button,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';

//styles
import { useStyles } from './style';

export default function StepTwo({
	highestLevelEducationLabel,
	educationScoreLabel,
	educationBacklogsLabel,
	gapEducationLabel,
	handleEducationGapClick,
	selectedEducationGap,
	setStep,
	HighestDegree,
	levelOfEducationSelect,
	levelOfEducation,
	educationScoreSelect,
	educationBacklogsChange,
	educationScore,
	error,
	educationBacklogs,
	setIsScroll,
	trackEvent,
}) {
	const { classes } = useStyles();
	const edcationGapEnum = [
		'0',
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'10+',
	];

	return (
		<>
			<Box className={classes.questionWrap}>
				<Typography className={classes.questionLabel}>
					{highestLevelEducationLabel}
				</Typography>
				<Select
					value={levelOfEducation}
					onChange={(e) => levelOfEducationSelect(e.target.value)}
					className={classes.highestEducation}
				>
					{HighestDegree ? (
						HighestDegree &&
						HighestDegree.map((name, key) => (
							<MenuItem key={key} value={name}>
								{name}
							</MenuItem>
						))
					) : (
						<MenuItem value="">
							<em>No Options</em>
						</MenuItem>
					)}
				</Select>
			</Box>
			<Box className={classes.educationContainer}>
				<Box
					className={`${classes.questionWrap} ${classes.levelEducation}`}
					style={{
						color: !levelOfEducation ? '#cccccc' : '#000000',
					}}
				>
					<Typography className={classes.questionLabel}>
						{educationScoreLabel}
					</Typography>
					<TextField
						type="number"
						onChange={(e) => educationScoreSelect(e)}
						value={educationScore}
						id="outlined-basic"
						variant="outlined"
						className={classes.scoreField}
						error={error.educationScore !== ''}
						helperText={error.educationScore}
						disabled={!levelOfEducation}
					/>
				</Box>
				<Box
					className={`${classes.questionWrap} ${classes.educationBacklogs}`}
					style={{
						color: !levelOfEducation ? '#cccccc' : '#000000',
					}}
				>
					<Typography className={classes.questionLabel}>
						{educationBacklogsLabel}
					</Typography>
					<TextField
						type="number"
						id="outlined-basic"
						variant="outlined"
						value={educationBacklogs}
						className={classes.scoreField}
						onChange={(e) => educationBacklogsChange(e)}
						error={error.educationBacklogs !== ''}
						helperText={error.educationBacklogs}
						disabled={!levelOfEducation}
					/>
				</Box>
			</Box>
			<Box className={classes.questionWrap}>
				<Typography
					className={classes.questionLabel}
					style={{
						color: educationBacklogs === '' ? '#cccccc' : '#000000',
					}}
				>
					{gapEducationLabel}
				</Typography>
				<Box className={classes.intakeOptionWrap}>
					<Select
						value={selectedEducationGap}
						onChange={(e) => handleEducationGapClick(e.target.value)}
						className={classes.highestEducation}
						disabled={
							!!(
								!levelOfEducation ||
								!educationScore ||
								educationBacklogs === '' ||
								!(educationBacklogs > 0 || educationBacklogs == 0) ||
								error.educationScore ||
								error.educationBacklogs
							)
						}
					>
						{edcationGapEnum ? (
							edcationGapEnum &&
							edcationGapEnum.map((name, key) => (
								<MenuItem key={key} value={name}>
									{name}
								</MenuItem>
							))
						) : (
							<MenuItem value="">
								<em>No Options</em>
							</MenuItem>
						)}
					</Select>
				</Box>
			</Box>
			<Box className={classes.buttonWrapper}>
				<Box>
					<Button
						variant="contained"
						color="success"
						onClick={() => {
							setStep(3);
							setIsScroll(true);
							trackEvent('BUTTON_CLICKED', {
								event_action: 'CLICKED',
								eventCategory: 'USER_EVENT',
								eventLabel: 'Submit',
								subSection: 'EDUCATION_DETAILS',
							});
						}}
						label={'Submit'}
						fullWidth
						className={classes.submitButton}
						disabled={
							!levelOfEducation ||
							!educationScore ||
							educationBacklogs === '' ||
							!(educationBacklogs > 0 || educationBacklogs == 0) ||
							error.educationScore ||
							error.educationBacklogs ||
							selectedEducationGap === ''
						}
					>
						Submit
					</Button>
				</Box>
				<Box>
					<Button onClick={() => setStep(1)} className={classes.backButton}>
						Back
					</Button>
				</Box>
			</Box>
		</>
	);
}

StepTwo.propTypes = {
	highestLevelEducationLabel: PropTypes.string,
	educationScoreLabel: PropTypes.string,
	educationBacklogsLabel: PropTypes.string,
	gapEducationLabel: PropTypes.string,
	handleEducationGapClick: PropTypes.func,
	selectedEducationGap: PropTypes.string,
	setStep: PropTypes.any,
	HighestDegree: PropTypes.any,
	levelOfEducationSelect: PropTypes.func,
	levelOfEducation: PropTypes.string,
	educationScoreSelect: PropTypes.func,
	educationBacklogsChange: PropTypes.func,
	educationScore: PropTypes.any,
	error: PropTypes.object,
	educationBacklogs: PropTypes.any,
	disabled: PropTypes.any,
	trackEvent: PropTypes.func.isRequired,
};
